<template>
  <div class="page">
    <!-- <div class="page-title" @click=">
      <img src="../../assets/img/zhang/back.png" alt="" />
      <span>处置已完成</span>
    </div> -->
    <div class="detail-header" @click="$router.go(-1)">
      <img src="../../assets/img/zhang/back.png" alt="" />
      <span>问题平台</span>
    </div>
    <!-- 筛选 -->
    <div class="selectBox">
      <!-- 平台类型 -->
      <PlatformCom ref="platform_type" :desc="'platform_type'"></PlatformCom>
      <!-- 主体类型 -->
      <PlatformCom ref="body_type" :desc="'body_type'"></PlatformCom>
      <!-- 处置状态 -->
      <!-- <div class="content-box">
        <div class="content-box-left">处置状态</div>
        <div class="content-box-right">
          <el-radio v-model="myInfo.disposalLogo" :label="0">未处置</el-radio>
          <el-radio v-model="myInfo.disposalLogo" :label="1">处置中</el-radio>
        </div>
      </div> -->
      <!-- 认证情况 -->
      <PlatformCom
        ref="is_authenticate"
        :desc="'is_authenticate'"
      ></PlatformCom>
      <!-- 条件栏 -->
      <el-row :gutter="20" style="position: relative">
        <el-col :span="20" class="selectBox-group-left">
          <!-- 标签 -->
          <div class="selectBox-group-item">
            <span>标签</span>
            <el-select
              class="w190 m-r-8 m-l-8"
              v-model="myInfo.label"
              multiple
              collapse-tags
              placeholder="请选择"
            >
              <el-option
                v-for="item in infoLabelList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <!-- 粉丝 -->
          <Fans ref="Fans" class="selectBox-group-item"></Fans>
          <!-- 月发文数量 -->
          <MonthlyIssue
            ref="MonthlyIssue"
            class="selectBox-group-item"
          ></MonthlyIssue>
          <!-- 选择条件 -->
          <div class="selectBox-group-item">
            <el-select
              class="w100"
              v-model="myInfo.searchType"
              placeholder="请选择"
            >
              <el-option
                v-for="item in myInfo.searchList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-input
              class="w190 m-r-8 m-l-8"
              v-model="myInfo.content"
              placeholder="请输入"
              clearable
              prefix-icon="el-icon-search"
            ></el-input>
          </div>
          <!-- 所在地区 -->
          <AreaMany ref="areaMany" class="selectBox-group-item"></AreaMany>
          <!-- 问题类型 -->
          <MessageType
            ref="MessageType"
            class="selectBox-group-item"
            :title="'问题类型'"
          ></MessageType>
        </el-col>
        <el-col :span="4">
          <div class="selectBox-group-btn">
            <div class="queryBtn" @click="toSearch">查询</div>
            <div class="resetBtn" @click="resetFn">重置</div>
            <div class="exportBtn" @click="exportDakai">
              <img src="../../assets/img/zhang/export.png" alt="" /><span
                >数据导出</span
              >
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 添加异常平台按钮 -->
    <div class="addAbnormal m-t-16 m-b-16">
      <Sort ref="sort" @sortFn="toSearch"></Sort>
      <!-- <div class="addAbnormalBtn" @click="addFn">
        <i class="el-icon-plus"></i>添加异常平台
      </div> -->
    </div>
    <!-- 加载中 -->
    <el-skeleton :rows="26" v-if="ifOpen" />
    <!-- 列表 -->
    <el-card shadow="never" class="platform" v-if="queryList.length > 0">
      <div class="platformInfo" v-for="(item, index) in queryList" :key="index">
        <div class="platformInfo-left">
          <div class="platformInfo-left-top"></div>
          <div class="platformInfo-left-bottom">
            {{ item.platformTypeName }}
          </div>
        </div>
        <div class="platformInfo-middle">
          <div
            class="middle-name cursor texthidden"
            @click="toDetail(1, item)"
            v-html="item.accountName ? item.accountName : ''"
          ></div>
          <div class="middle-species">
            <div class="mr32">
              <span>状态类型：</span>
              <span class="colRed" :class="{'colGreen':item.colGreen}"
                >{{ item.messageTypeNameList[0] + "   " }}
              </span>
              <el-tooltip class="item" effect="dark" placement="top">
                <div slot="content" v-html="item.messageTypeNameDesc"></div>
                <span>(更多)</span>
              </el-tooltip>
            </div>
            <div class="mr32 region">
              <span>所属地区：</span
              ><span>{{ $main.ifText(item.areaName) }}</span>
            </div>
            <div class="mr32">
              <span>处置状态：</span
              ><span
                v-html="item.disposalLogoName ? item.disposalLogoName : ''"
              ></span>
            </div>
            <div class="mr32">
              <span>主体类型：</span
              ><span>{{ $main.ifText(item.bodyTypeName) }}</span>
            </div>
            <template v-if="item.platformType == 1">
              <div class="mr32 region">
                <span>主办单位：</span>
                <div class="texthidden m-l-8">
                  {{ $main.ifText(item.companyName) }}
                </div>
              </div>
              <div class="mr32">
                <span>ICP备案：</span
                ><span>{{ $main.ifText(item.icpNumber) }}</span>
              </div>
            </template>

            <template v-else>
              <div class="mr32">粉丝数：{{ $main.ifText(item.fans) }}</div>
              <div class="mr32">关注数：{{ $main.ifText(item.attention) }}</div>
            </template>
            <div class="mr32">
              发文数：{{ item.contentCount }}
            </div>
          </div>
          <div class="middle-select">
            <span>标签</span>
            <el-select
              class="w120"
              @change="ChangeFn(item, $event)"
              v-model="item.labelId"
              placeholder="请选择"
              clearable
            >
              <el-option
                v-for="item2 in infoLabelList"
                :key="item2.value"
                :label="item2.label"
                :value="item2.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="platformInfo-right">
          <div class="platformInfo-right-top cursor" @click="toDetail(4, item)">
            有害信息({{ item.harmfulCnt }})
          </div>
          <div class="platformInfo-right-bottom">
            <div class="desc cursor" @click="toDetail(1, item)">
              <img src="../../assets/img/zhang/chuzhixiangqing.png" alt="" />
              <div>处置详情</div>
            </div>
            <div class="desc m-l-32 cursor" @click="toDetail(2, item)">
              <img src="../../assets/img/zhang/detail.png" alt="" />
              <div>查看详情</div>
            </div>
            <div class="desc m-l-32 cursor" @click="toDetail(3, item)">
              <img src="../../assets/img/zhang/content-info.png" alt="" />
              <div>内容信息</div>
            </div>
          </div>
        </div>
      </div>
      <Pagination
        :pageNumber="page.pageNo"
        :pageSize="page.pageSize"
        @SizeChange="SizeChange"
        @CurrentChange="CurrentChange"
        :total="total"
      />
    </el-card>
    <!-- 位图 -->
    <Bitmap :desc="'列表'" v-else />
    <!-- 添加异常平台 弹框 -->
    <Left ref="LeftDialog" @okFn="okFn" />
    <!-- 数据导出 -->
    <exportDialog ref="exportDialog"></exportDialog>
  </div>
</template>
  
  <script>
import Breadcrumb from "@/components/breadcrumb.vue";
import Pagination from "../../components/pagination";
import MonthlyIssue from "../../components/dressing/MonthlyIssue.vue"; // 月发文数量
import Fans from "../../components/dressing/Fans.vue"; // 粉丝
import MessageType from "../../components/dressing/MessageType.vue"; // 问题类型
import PlatformCom from "../../components/dressing/PlatformCom.vue"; //  筛选框 全选多选
import AreaMany from "../../components/areaMany.vue"; // 地区多选
import Sort from "./components/sort.vue"; // 排序
import exportDialog from "../../components/exportDialog.vue";
import Left from "./components/left.vue";
import Bitmap from "../../components/bitmap.vue"; // 位图
import {
  queryPage,
  getList,
  exportExcel,
  account,
} from "../../api/platformaccount/index";
export default {
  components: {
    Pagination,
    Breadcrumb,
    Bitmap, // 位图
    Left,
    exportDialog, // 导出
    Sort, // 排序
    AreaMany, // 多选地图
    PlatformCom, // 多选框子
    MessageType, // 有害信息的类型
    Fans, // 粉丝
    MonthlyIssue, // 月发文数量
  },
  data() {
    return {
      routerList: ["问题平台", "处置已完成"], //面包屑
      ifOpen: true, // 加载中
      // 筛选栏目的信息
      myInfo: {
        textNum: null, //月发文数量
        textSection: true, //月发文数量区间
        searchType: 0, //搜索类型
        searchList: [
          { label: "名称", value: 0 },
          // { label: "地址", value: 1 },
          { label: "主办单位", value: 2 },
        ],
        content: "", //搜索内容
        address: "", //所在地区
        addressList: [{ label: "陕西", value: 0 }], //地区
        label: [], //已选标签
        labelList: [],
        disposalLogo: 2, // 未处置 || 处置中 || 已处置
      },
      infoLabelList: [], // 标签
      queryList: [], //列表
      page: {
        pageNo: 1,
        pageSize: 10,
      },
      total: 100,
    };
  },
  mounted() {
    this.queryPage();
  },
  created() {
    this.getList(); // 标签列表
  },
  methods: {
    exportDakai() {
      this.toSearch({ ifReturn: 1 });
    },
    // 导出
    async exportFN(obj) {
      //                        请求函数，参数配置,组件配置
      await this.$main.exportFN(
        exportExcel,
        {
          ...obj,
          // abnormal: true
        },
        this.$refs,
        1,
        "问题平台导出.xlsx"
      );
    },
    async okFn(obj) {
      const Res = await addAbnormalPlatform(obj);
      if (Res.data.code == 200) {
        this.$message.success("添加异常平台成功！");
        this.$refs.LeftDialog.handleClose();
      } else {
        this.$refs.LeftDialog.handleClose();
      }
    },
    // 新增监测方案 or 打开弹框
    addFn() {
      this.$refs.LeftDialog.dakai(1, this.monitorData); // type 1：保存 2：编辑
    },
    yiwancheng() {
      this.$router.push("/completed");
    },
    // 列表
    async queryPage(myInfo) {
      this.ifOpen = true;
      this.queryList = [];
      const { page } = this;
      const item = {
        ...page,
        // abnormal: true ,
        statHarm: true,
        disposalLogo: this.myInfo.disposalLogo,
      };
      try {
        myInfo = { ...myInfo, sort: this.$refs.sort.getSortData() }; // 这是选择器
        const Res = await queryPage(myInfo ? { ...myInfo, ...item } : item);
        const {
          data: { list, totalCount },
        } = Res.data;
        list.forEach((v) => {
          if (v.messageTypeNameList.length > 0) {
            v.messageTypeNameDesc = v.messageTypeNameList
              .join(" ")
              .replace(new RegExp(" ", "g"), "<br/>");
            v.colGreen = false
          }else{
            v.messageTypeNameDesc = ['状态正常'].join(" ")
              .replace(new RegExp(" ", "g"), "<br/>");
            v.messageTypeNameList = ['状态正常']
            v.colGreen = true
          }
        });
        this.ifOpen = false;
        this.queryList = list;
        this.total = Number(totalCount);
      } catch {
        this.ifOpen = false;
      }
    },
    // 标签列表 请求
    async getList() {
      const Res = await getList({});
      // if(this.$main.ifRes(Res)) return
      const {
        data: { list },
      } = this.$main.getResData(Res);
      this.infoLabelList = list.map((item) => ({
        value: item.id,
        label: item.name,
      }));
    },
    // 列表标签 方法
    async ChangeFn(item, e) {
      const { accountId, platformType, labelIds } = item;
      const Res = await account({
        labelIds: labelIds ? labelIds + "," + e : e,
        accountId,
        platformType,
      });
      const { code } = Res.data;
      if (code == 200) {
        this.$message({
          message: `修改标签成功`,
          type: "success",
        });
      } else {
        this.$message.error("修改失败");
      }
    },
    //跳转详情
    toDetail(inx, item) {
      // 1:处置详情 跳详情页
      // 2：查看详情 平台台账详情
      // 3：内容信息 跳发布内容列表页
      // 4: 有害信息 跳向有害信息列表
      const routeObj = {
        1: { path: "/questionDetail", query: { item } },
        2: { path: "/accountDetail", query: item },
        3: { path: "/publishingcontent", query: { ...item, pageType: 1 } },
        4: { path: "/harmful", query: { ...item, pageType: 1 } },
      };
      const { path, query } = routeObj[inx];
      this.$main.routeResolve(path, query);
    },
    // 查询
    toSearch({ ifReturn, ifpageType } = {}) {
      //  type 存在 代表会返回成对象
      //  pageType 存在 代表是分页按钮点击的  如果不存在 那么分页默认第一页
      if (!ifpageType) {
        this.page.pageNo = 1;
      }
      const { myInfo, page, $refs } = this;
      const obj = {
        platformTypeList: $refs.platform_type.formObj.checkedform.map(
          (v) => v.value
        ), //平台类型 
        bodyTypeList: $refs.body_type.formObj.checkedform.map((v) => v.value), //主体类型
        messageTypeList: $refs.MessageType.status, // 有害信息类型
        labelList: myInfo.label, // 标签
        authenticateList: $refs.is_authenticate.formObj.checkedform.map(
          (v) => v.value
        ), // 认证情况
      };
      // 粉丝
      if ($refs.Fans.fans.cnt && $refs.Fans.fans.cnt > 0) {
        obj.fans = $refs.Fans.fans;
        obj.fans.cnt = Number(obj.fans.cnt);
      }
      // 月发文
      if (
        $refs.MonthlyIssue.contentCount.cnt &&
        $refs.MonthlyIssue.contentCount.cnt > 0
      ) {
        obj.contentCount = $refs.MonthlyIssue.contentCount;
        obj.contentCount.cnt = Number($refs.MonthlyIssue.contentCount.cnt);
      }

      if (myInfo.searchType == 0 && myInfo.content.length > 0)
        obj.accountName = myInfo.content;
      if (myInfo.searchType == 2) obj.companyName = myInfo.content;
      // 地区选择
      if (this.$refs.areaMany.ownRegion.length > 0) {
        obj.provinceIdList = this.$refs.areaMany.backOwnRegion(0);
        obj.cityIdList = this.$refs.areaMany.backOwnRegion(1);
        obj.districtIdList = this.$refs.areaMany.backOwnRegion(2);
      }
      // type 为1 调用导出
      if (ifReturn) {
        this.exportFN({ ...obj, ...page });
        return;
      }
      this.queryPage(obj);
    },
    // 重置
    resetFn() {
      this.$refs.MessageType.status = [];
      this.myInfo.label = [];
      this.$refs.Fans.fans = { above: true, cnt: null }; // 粉丝
      (this.$refs.MonthlyIssue.contentCount = { cnt: null, above: true }), // 月发文
        (this.myInfo.content = "");
      this.$refs.platform_type.resetCom(); // 平台类型
      this.$refs.body_type.resetCom(); // 主体类型
      this.$refs.is_authenticate.resetCom(); // 认证情况
      this.$refs.areaMany.ownRegion = [];
      this.myInfo.searchType = 0;
      this.myInfo.disposalLogo = 2; //处置
      this.toSearch();
    },
    // 分页  页码
    CurrentChange(val) {
      this.page.pageNo = val;
      this.toSearch({ ifpageType: 1 });
    },
    // 分页  条数
    SizeChange(val) {
      this.page.pageSize = val;
      this.page.pageNo = 1;
      this.toSearch();
    },
  },
};
</script>
<style lang="scss" scoped>
.detail-header {
  width: 100%;
  height: 56px;
  background-color: #ffffff;
  display: flex;
  justify-content: start;
  img {
    width: 38px;
    height: 38px;
    margin: 9px 15px 9px 23px;
    cursor: pointer;
  }
  span {
    font-size: 20px;
    font-weight: 600;
    line-height: 56px;
    font-family: PingFangSC-Semibold, PingFang SC;
  }
}
.page-title {
  display: flex;
  justify-content: space-between;
  height: 36px;
  margin-bottom: 24px;
  line-height: 36px;
  font-weight: 600;
  font-family: PingFangSC-Medium, PingFang SC;
  .page-title-left {
    font-size: 26px;
  }
  .page-title-right {
    font-size: 18px;
    cursor: pointer;
    i {
      font-size: 18px;
      font-weight: 600;
    }
  }
}
// 处置状态
.content-box {
  display: flex;
  min-height: 52px;
  width: 100%;
  height: auto;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #999999;
  border-bottom: 1px solid #e4e6ec;
  .content-box-left {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 16px;
    width: 96px;
    height: 100%;
  }
  .content-box-right {
    display: flex;
    width: calc(100% - 96px);
    height: 100%;
    .platform-check-all {
      //全选
      margin: 16px 48px 0px 0px;
      ::v-deep .el-checkbox__label {
        color: #333333;
      }
      ::v-deep .el-checkbox__inner {
        border-color: #e4e6ec;
      }
      ::v-deep .el-checkbox__input.is-checked {
        .el-checkbox__inner {
          background-color: #f58030;
          border-color: #f58030;
        }
      }
      ::v-deep .el-checkbox__input.is-indeterminate {
        //半选
        .el-checkbox__inner {
          background-color: #f58030;
          border-color: #f58030;
        }
      }
    }
    .platform-group {
      width: calc(100% - 100px);
      height: 100%;
      padding-bottom: 16px;
      .platform-check-more {
        //多选
        margin: 16px 48px 0px 0px;
        ::v-deep .el-checkbox__label {
          color: #333333;
        }
        ::v-deep .el-checkbox__inner {
          border-color: #e4e6ec;
        }
      }
      ::v-deep .el-checkbox.check-more.is-checked {
        .el-checkbox__inner {
          background-color: #f58030;
          border-color: #f58030;
        }
      }
    }
    ::v-deep .el-radio {
      margin-top: 18px;
      margin-right: 34px;
      .el-radio__label {
        color: #333333;
      }
      .el-radio__inner {
        border-color: #e4e6ec;
      }
      .el-radio__input.is-checked {
        .el-radio__inner {
          background-color: #f58030;
        }
      }
    }
  }
}

.queryBtn {
  width: 60px;
  height: 36px;
  background-color: #f58030;
  color: white;
  line-height: 36px;
  text-align: center;
  border-radius: 4px;
  margin-right: 16px;
  cursor: pointer;
}
.resetBtn {
  width: 60px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  border-radius: 4px;
  color: #000;
  border: 1px solid #e1e1e1;
  margin-right: 16px;
  cursor: pointer;
}
.exportBtn {
  width: 97px;
  height: 36px;
  line-height: 32px;
  text-align: center;
  background-color: #fff1e8;
  border-radius: 4px;
  color: #f58030;
  cursor: pointer;
  img {
    height: 14px;
    width: 14px;
    margin-right: 2px;
    vertical-align: middle;
  }
  span {
    vertical-align: middle;
  }
}
.addAbnormal {
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
}
.addAbnormalBtn {
  float: right;
  width: 128px;
  height: 36px;
  line-height: 36px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  text-align: center;
  background-color: #f58030;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  margin: 16px 0;
}
.platform {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  background: #ffffff;
  border-radius: 4px;
  padding: 24px;
  .platformInfo {
    position: relative;
    display: flex;
    justify-content: start;
    width: 100%;
    height: 156px;
    padding: 24px 0;
    border-bottom: 1px solid #e4e6ec;
    .platformInfo-left {
      width: 88px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: center;
      margin-right: 24px;
      .platformInfo-left-top {
        width: 56px;
        height: 56px;
        border-radius: 50%;
        background: #fff2e9;
        margin: 10px 0 9px 0;
      }
      .platformInfo-left-bottom {
        width: 72px;
        height: 24px;
        background: #f58030;
        color: white;
        text-align: center;
        font-size: 14px;
        line-height: 24px;
      }
    }
    .platformInfo-middle {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      // width: 1080px;
      width: calc(100% - 100px);
      height: 100%;
      // margin-right: 110px;
      .middle-name {
        width: 80%;
        height: 25px;
        line-height: 25px;
        font-size: 18px;
        font-weight: 600;
      }
      .middle-species {
        display: flex;
        justify-content: start;
        // width: 135%;
        width: 100%;
        height: 20px;
        line-height: 20px;
        font-size: 14px;
        .mr32 {
          display: flex;
          margin-right: 32px;
          span {
            display: block;
            max-width: 180px;
            min-width: 20px;
          }
          .colRed {
            color: red;
            max-width: 40px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .colGreen{
            color: #1ed71e;
            max-width: 40px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
      .middle-select {
        span {
          margin-right: 6px;
        }
      }
    }
    .platformInfo-right {
      position: absolute;
      right: 0;
      top: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      // width: 286px;
      width: auto;
      height: 100%;
      padding: 24px 0;
      .platformInfo-right-top {
        text-align: right;
        font-size: 14px;
        color: #e60b1e;
        font-family: PingFangSC-Medium, PingFang SC;
      }
      .platformInfo-right-bottom {
        display: flex;
        font-family: PingFangSC-Medium, PingFang SC;
        font-size: 14px;
        .desc {
          display: flex;
          align-items: center;
          cursor: pointer;
          img {
            margin-right: 3px;
          }
          div {
            display: inline-block;
          }
        }
      }
    }
  }
}
.region {
  display: flex;
  align-items: center;
  max-width: 220px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 46px;
}
</style>